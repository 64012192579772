import directus from "../directus-client";

export async function getArticles(pageNumber: number = 1, pageSize: number = 9, language: string = "en-US", sortOrder: string = "-published_date") {
    return await directus().query<any>(`
        query {
            articles (limit: ${pageSize}, offset: ${(pageNumber - 1) * pageSize},
            filter: {translations: {languages_code: {code: {_eq: "${language}"}}}}
            sort: [ "${sortOrder}" ]
            ) {
              slug,
              image {
                id
              },
              author {
                name
              },
               nofollow,
               noindex,
             popular,
             published_date,
             translations (
                filter: {languages_code: {
                    code: {_eq: "${language}"}
                }},
             ){
                content
                title
                meta_description
                meta_title
             }
            },
            
            slug_count: articles_aggregated (filter: {translations: {languages_code: {code: {_eq: "${language}"}}}}) {
                count {
                    slug
                }
            },
            
            popular_articles: articles(filter: {
                popular: {_eq: true},
                translations: {languages_code: {code: {_eq: "${language}"}}}
            }, limit: 3) {
                    slug,
                    image {
                        id
                    },
                    author {
                        name
                    },
                    popular,
                    published_date,
                    translations (
                        filter: {languages_code: {
                            code: {_eq: "${language}"}
                        }},
                     ){
                        content
                        title
                        meta_description
                        meta_title
                     }
           },
           
           recent_articles: articles(sort: [ "-published_date" ], limit: 2, filter: {translations: {languages_code: {code: {_eq: "${language}"}}}}) {
                slug,
                image {
                    id
                },
                author {
                    name
                },
                popular,
                published_date, 
                translations (
                        filter: {languages_code: {
                            code: {_eq: "${language}"}
                        }},
                     ){
                        content
                        title
                        meta_description
                        meta_title
                }
           },
          
        }
`);
}

export async function getArticle(slug: string | undefined, language: string = "en-US") {
    return await directus().query<any>(`
        query {
            articles(filter: {
                slug: {_eq: "${slug}"},
                translations: {languages_code: {code: {_eq: "${language}"}}}
            }) {
              slug,
              image {
                id
              },
              author {
                name,
                Author_description
              },
              published_date,
              translations (
                filter: {languages_code: {
                    code: {_eq: "${language}"}
                }},
             ){
                content
                title
                meta_description
                meta_title
             }
            }
        }
`);
}

export async function searchArticles(keyword: string, language: string = "en-US") {
    return await directus().query<any>(`
        query {
            articles(
                search: "${keyword}",
                filter: {translations: {languages_code: {code: {_eq: "${language}"}}}}
            ) {
              slug,
              image {
                id
              },
              author {
                name
              },
              published_date,
              translations (
                filter: {languages_code: {
                    code: {_eq: "${language}"}
                }},
             ){
                content
                title
                meta_description
                meta_title
             }
            }
        }
`);
}

export async function getSectionByName(sectionName: string, language: string = "en-US") {
    return await directus().query<any>(`
        query {
          sections(filter: {
                translations: {
                  sections_name: {
                    name: {
                      _eq: "GetStartedNowSection"
                    }
                  },
                  languages_code: {
                    code: {
                      _eq: "en-US"
                    }
                  }
                }
              }) {
            translations {
              content
            }
          }
        }
    `);
}

export async function getLayoutContents(language: string = "en-US") {
    return await directus().query<any>(`
        query {
            topmenu(filter: { translations: {languages_code: { code: {_eq: "${language}" } } } }) {
            translations {
              name
              slug
            }
          }
          footermenu(filter: { translations: {languages_code: { code: {_eq: "${language}" } } } }) {
            translations {
              name
              slug
            }
          }
          faqs {
            translations(filter: {languages_code: { code: {_eq: "${language}" } } }) {
              question
              answer
            }
          }
          testimonials {
            fullname
            testimonial
            avatar {
              id
            }
          }
          
          home_page_sections {
            translations(filter: { languages_code: { code: { _eq: "${language}"  } }}) {
              WelcomeTitle
              FreeCredits
              Section1Title
              Section1Content
              Section2Title
              Section2Content
              CupTitle
              CupContent
              RateTitle
              RateContent
              TimeTitle
              TimeContent
              ThunderBoltTitle
              ThunderBoltContent
              TestimonialTitle
              TestimonialContent
              meta_title
              meta_description
            }
          }
          GetStartedSection {
            translations(filter: { languages_code: { code: { _eq: "${language}"  } }}) {
              Title
              Content
            }
          }
          
           sections {
            translations(filter: {languages_code: { code: {_eq: "${language}" } } }) {
              sections_name {
                name
              }
              content
            }
          }
          
        }
    `);
}

export async function getProductContent(language: string = "en-US") {
    return await directus().query<any>(`
        query {
        products_page {
            translations(filter: {languages_code: { code: {_eq: "${language}" } } }) {
              title
              green_title
              green_subtitle
              green_content
              white_title
              white_content
              pie_title
              pie_content
              meta_title
              meta_description
            }
          }
        }
    `);
}

export async function getPricingContent(language: string = "en-US") {
    return await directus().query<any>(`
        query {
        pricing {
            translations(filter: {languages_code: { code: {_eq: "${language}" } } }) {
              pricing_name {
                name
              }
              description
              features
            }
          }
        }
    `);
}

export const fetchLayoutContent = async () => {
    const cache = await caches.open("layoutContents");

    const cachedResponse = await cache.match("layoutContents");

    if (cachedResponse) {
        return cachedResponse.json();
    }

    return await getLayoutContents().then((res) => {
        cache.put("layoutContents", new Response(JSON.stringify(res)));

        return res;
    }).catch((err) => {
        return err;
    });
}

export async function getRoutePages(routeName: string, language: string = "en-US") {
    return await directus().query<any>(`
        query {
            route_pages_translations(filter: {
                languages_code: {
                  code: {
                    _eq: "${language}"
                  }
                },
                route_pages_route: {
                  route: {
                    _eq: "${routeName}"
                  }
                }
              }) {
            content
          }
        }
    `);
}



