import {createContext, useContext, useEffect, useState} from 'react';

import {AccountInterface} from '../Models/Account';

interface ContextState {
    setUserAccount: (account: AccountInterface) => void;
    userAccountDetails: AccountInterface;
    isAuthenticated: () => boolean;
    showPricingPage: () => boolean;
    setGoToPricing: (show: boolean) => void;
}

type Props = {
    children: React.ReactNode;
};

const AppContext = createContext({} as ContextState);

const AppProvider = ({children}: Props) => {
    //TODO: reftch from user account endpoint
    // const getInitialAccountState = () => Storage.get("userAccount") || null;
    const [userAccountDetails, setUserAccountDetails] =
        useState<AccountInterface>({});

    const setUserAccount = (account: AccountInterface) => {
        setUserAccountDetails(account);
        // //TODO: Remove this
        // Storage.saveToLocalStorage("userAccount", account);
    };

    const isAuthenticated = () => {
        return !!userAccountDetails?.email;
    };

    const setGoToPricing = (show: boolean) => {
        localStorage.setItem('showPricingPage', JSON.stringify(show));
    }

    const showPricingPage = () => {
        return JSON.parse(localStorage.getItem('showPricingPage') || 'false');
    }

    return (
        <AppContext.Provider
            value={{
                setUserAccount,
                userAccountDetails,
                isAuthenticated,
                showPricingPage,
                setGoToPricing
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export const useAppData = (): ContextState => {
    const appData = useContext(AppContext);
    if (appData === undefined) {
        throw new Error('useAppData must be used within a AppProvider');
    }
    return appData;
};

export default AppProvider;
