import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ReloadOutlined, ArrowUpOutlined, CloseOutlined} from '@ant-design/icons';

import {ReactComponent as LogoIcon} from "../Assests/Images/logo.svg";


import {ROUTE_CONSTANTS} from "../Routes/RouteConstants";

import AccountingService from "../Services/Accounting/AccountingService";

import TokenHandler from "../Utils/TokenHandler";
import storageHandler from "../Utils/StorageHandler";
import Toast from "../Utils/ToastHandler";

import {TABLET_MAX_WIDTH_QUERY, TOAST_BOTTOM_CENTER, TOAST_DURATION} from "../Constants/Values";

import "./PrivateWrapper.scss";
import {Skeleton, Spin, Switch, TimePicker} from "antd";
import TermsOfService from "../Pages/Terms";
import {Link} from "react-router-dom";
import Footer from "../Components/Resources/Footer/Footer";
import ProfileSide from "../Components/ProfileSide/ProfileSide";
import useMatchMedia from "../Hooks/useMatchMedia";
import SubscriptionService from "../Services/Subscription/SubscriptionService";
import {SubscriptionProps} from "../Models/Subscription";

const {
    REACT_APP_PAYMENT_MODEL
} = (window as any).__env__;

type Props = {
    children: React.ReactNode;
};

const PrivateWrapper = ({children}: Props) => {
    const {t} = useTranslation();
    const externalReference = TokenHandler?.getDecodedToken()?.UserExternalReference;
    const [balance, setBalance] = useState("0");
    const [loading, setLoading] = useState<boolean>(false);
    const [isTermsOpened, setIsTermsOpened] = useState<boolean>(false);
    const {match: isMobileDevice} = useMatchMedia(TABLET_MAX_WIDTH_QUERY);

    const [subscription, setSubscription] = useState<SubscriptionProps>({
        startingPaymentDate: "",
        name: "",
        durationInMonth: 0,
        currencyIso3: "",
        amount: 0,
        isSuspended: false,
        autoRenewable: false,
    });

    const [userName, setUserName] = useState<any>({
        firstName: "",
        lastName: "",
    });

    const [isProfileTab, setIsProfileTab] = useState<boolean>(false);

    const getBalanceHandler = () => {
        setLoading(true);
        AccountingService.getBalance().then((response) => {
            setBalance(response);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }

    const subscriptionStatusHandler = () => {
        const subscriptionName = subscription.name;
        const subscriptionIsSuspended = subscription.isSuspended;

        if (subscriptionIsSuspended) {
            return t("profile-subscription-Suspended"); //if its suspended, then its suspended
        } else {
            return t(subscriptionName);
        }
    }

    const subscriptionButtonHandler = (buttonClassName: string) => {
        const subscriptionIsSuspended = subscription.isSuspended;
        const subscriptionAutoRenewable = subscription.autoRenewable;

        if (subscriptionIsSuspended) { //if its suspended, then show subscribe button
            return <button
                className={buttonClassName}
                onClick={() => {
                    window.location.href = ROUTE_CONSTANTS.TOP_UP;
                }}
            >
                <span
                    className="text-sm font-sans font-medium text-white">
                    {t("profile-subscribe-button")}
                </span>
            </button>
        } else if (!subscriptionAutoRenewable) { //if its not auto renewable, then show subscribe button
            return <button
                className={buttonClassName}
                onClick={() => {
                    window.location.href = ROUTE_CONSTANTS.TOP_UP;
                }}
            >
                <span
                    className="text-sm font-sans font-medium text-white">
                    {t("profile-subscribe-button")}
                </span>
            </button>
        } else if (!subscriptionIsSuspended && subscriptionAutoRenewable) { //if its not suspended and auto renewable, then show invoice button
            return <button
                className={buttonClassName}
            >
                <span
                    className="text-sm font-sans font-medium text-white">
                    {t("profile-subscription-Invoice")}
                </span>
            </button>
        }
    }

    const getCurrentSubscription = () => {
        setLoading(true);
        SubscriptionService.getCurrentSubscription().then((response: any) => {
            setSubscription({
                startingPaymentDate: response?.startingPaymentDate,
                name: response?.name,
                durationInMonth: response?.durationInMonth,
                currencyIso3: response?.currencyIso3,
                amount: response?.amount,
                isSuspended: response?.isSuspended || false,
                autoRenewable: response?.autoRenewable || false,
            });
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }

    const subscriptionActionHandler = () => {
        if (REACT_APP_PAYMENT_MODEL !== "CREDIT") {
            getCurrentSubscription();
        } else {
            getBalanceHandler()
        }
    }

    useEffect(() => {
        externalReference && subscriptionActionHandler();

        const urlParams = new URLSearchParams(window.location.search);
        const tab = urlParams.get("tab");
        if (tab === "profile") {
            setIsProfileTab(true);
        }

    }, []);

    return (<div
            style={{
                background: !isMobileDevice ? "linear-gradient(90deg, #FFFFFF 65%, #F0F0F0 35%)"
                    : "#FFFFFF"
            }}
        >
            <div className="xl:px-16">
                <div className={`col xl:flex`}>
                    <div className="col-md-12 col-sm-12 col-lg-12 col-xl-9">
                        <LogoIcon className="mt-7 hidden xl:block"/>
                        <div className="border-t-2 border-gray-light mt-3 hidden xl:block"/>

                        <div className="xl:hidden">
                            <div className="bg-blue w-full h-[257px] mobile-header">
                                <div
                                    className={`h-full w-full px-7`}
                                >
                                    <div
                                        className={`flex-row justify-center gap-3 items-center mt-12 mb-7  ${window.location.pathname === ROUTE_CONSTANTS.TOP_UP ? "hidden" : "flex "}`}>
                                        <button
                                            className={`rounded-full py-[12px] w-48 flex items-center justify-center ${isProfileTab ? "bg-white text-blue-lighter border-2 border-white" : "bg-transparent border-2 border-white text-white"}`}
                                            onClick={() => setIsProfileTab(true)}
                                        >
                                            <span
                                                className={`text-base font-sans font-normal ${isProfileTab ? "text-blue-lighter" : "text-white"} `}
                                            >{t("private-wrapper-profile")}</span>

                                        </button>

                                        <button
                                            className={`rounded-full py-[12px] w-48 flex items-center justify-center ${!isProfileTab ? "bg-white text-blue-lighter border-2 border-white" : "bg-transparent border-2 border-white text-white"}`}
                                            onClick={() => setIsProfileTab(false)}
                                        >
                                            <span
                                                className={`text-base font-sans font-normal ${!isProfileTab ? "text-blue-lighter" : "text-white"} `}
                                            >{t("private-wrapper-game-records")}</span>
                                        </button>
                                    </div>

                                    {window.location.pathname === ROUTE_CONSTANTS.TOP_UP &&
                                        <div className="flex flex-row justify-between items-center mt-4 mb-1">
                                            <div></div>
                                            <h5 className="text-base font-sans font-medium text-white">
                                                {REACT_APP_PAYMENT_MODEL === "CREDIT" ? t("topup-title") : t("topup-pricing-title")}
                                            </h5>
                                            <CloseOutlined
                                                rev={undefined}
                                                onClick={() => {
                                                    window.location.href = ROUTE_CONSTANTS.DASHBOARD;
                                                }}
                                                className="hand-pointer text-white"
                                            />
                                        </div>}

                                    {REACT_APP_PAYMENT_MODEL === "CREDIT" ?
                                        <div className="ps-2">
                                            <h5 className="text-sm font-sans font-medium text-white">
                                                {t("private-wrapper-balance")}
                                            </h5>

                                            <div className="flex items-center justify-between">
                                                <div className="flex flex-col">
                                                    {!loading ? <div className="flex">
                                                        <h5 className="text-5xl font-sans font-black text-white p-0 m-0">{balance || 0}</h5>
                                                        <ArrowUpOutlined rev={undefined} className="text-lemon-green"/>
                                                    </div> : <Skeleton.Input size="large" active={true}/>}
                                                </div>

                                                <button
                                                    className="flex bg-white-50 items-center justify-center bg-gray-darker rounded-full w-32 py-[15px]"
                                                    onClick={() => {
                                                        window.location.href = ROUTE_CONSTANTS.TOP_UP;
                                                    }}
                                                >
                                                <span
                                                    className="text-sm font-sans font-medium text-white">{t("topup-title")}</span>
                                                </button>
                                            </div>
                                        </div> : <div className="ps-2">
                                            <h5 className="text-sm font-sans font-medium text-white">
                                                {t("profile-subscription-status")}
                                            </h5>

                                            <div className="flex items-center justify-between">
                                                <div className="flex flex-col">
                                                    {!loading ? <div className="flex">
                                                        <h5 className="text-4xl font-sans font-black text-white p-0 m-0">
                                                            {subscriptionStatusHandler()}
                                                        </h5>
                                                    </div> : <Skeleton.Input size="large" active={true}/>}
                                                </div>

                                                {subscriptionButtonHandler("flex bg-white-50 items-center justify-center bg-gray-darker rounded-full w-32 py-[15px]")}
                                            </div>
                                        </div>
                                    }

                                </div>

                            </div>
                        </div>

                        {isProfileTab && isMobileDevice ? <div
                                className="px-3 w-full"
                            >
                                <ProfileSide
                                    setUserName={setUserName}
                                    loading={loading}
                                    setLoading={setLoading}
                                    fetchBalance={getBalanceHandler}
                                    currentSubscription={subscription}
                                />
                                <div className="flex justify-center items-center my-8">
                                    <Link
                                        to=""
                                        className="text-base font-sans font-medium text-blue-dark underline"
                                        onClick={() => {
                                            storageHandler.clear();
                                            window.location.href = "/";
                                        }}
                                    >{t("private-wrapper-log-out")}</Link>
                                </div>
                            </div>
                            :
                            children
                        }
                    </div>

                    {/*hidden on mobile and tablet and only visible on desktop*/}
                    <div className="col-lg-3 col-md-4 col-sm-4 hidden xl:block">

                        {externalReference &&
                            <div className="bg-blue p-4 rounded-[25px] mt-20">
                                <div className="flex flex-col justify-center items-center">
                                    <h5 className="text-white mt-3 text-lg font-sans font-medium">
                                        {loading ? <Skeleton.Input size="default" active={true}/> :
                                            `${userName.firstName} ${userName.lastName}`
                                        }
                                    </h5>

                                    {REACT_APP_PAYMENT_MODEL === "CREDIT" ?
                                        <div className="flex flex-col justify-center items-center">
                                            <div className="mt-8">
                                                <h5 className="text-sm font-sans font-medium text-white">
                                                    {t("private-wrapper-balance")}
                                                </h5>

                                                <div className="flex">
                                                    {!loading ? <div className="flex">
                                                        <h5 className="text-4xl font-sans font-black text-white">{balance || 0}</h5>
                                                        <ArrowUpOutlined rev={undefined} className="text-lemon-green"/>
                                                    </div> : <Skeleton.Input size="default" active={true}/>}
                                                </div>
                                            </div>

                                            <button
                                                className="flex bg-white-50 items-center bg-gray-darker rounded-full px-20 py-3 mt-8 mb-3"
                                                onClick={() => {
                                                    window.location.href = ROUTE_CONSTANTS.TOP_UP;
                                                }}
                                            >
                                        <span
                                            className="text-sm font-sans font-medium text-white">
                                        {REACT_APP_PAYMENT_MODEL === "CREDIT" ? t("topup-title") : t("topup-pricing-title")}
                                        </span>
                                            </button>
                                        </div> : <div className="flex flex-col justify-center items-center">
                                            <div className="mt-8">
                                                <h5 className="text-sm font-sans font-medium text-white">
                                                    {t("profile-subscription-status")}
                                                </h5>

                                                <div className="flex">
                                                    {!loading ? <div className="flex">
                                                        <h5 className="text-3xl font-sans font-black text-white uppercase">
                                                            {subscriptionStatusHandler()}
                                                        </h5>
                                                    </div> : <Skeleton.Input size="default" active={true}/>}
                                                </div>
                                            </div>

                                            {subscriptionButtonHandler("flex bg-white-50 items-center bg-gray-darker rounded-full px-20 py-3 mt-8 mb-3")}
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        <ProfileSide
                            setUserName={setUserName}
                            loading={loading}
                            setLoading={setLoading}
                            fetchBalance={getBalanceHandler}
                            currentSubscription={subscription}
                        />

                        <div className="flex justify-center items-center my-8">
                            <Link
                                to=""
                                className="text-base font-sans font-medium text-blue-dark underline"
                                onClick={() => {
                                    storageHandler.clear();
                                    window.location.href = "/";
                                }}
                            >{t("private-wrapper-log-out")}</Link>
                        </div>

                    </div>

                </div>
            </div>

            <Footer/>

            {/*{loading && <div className="spinner-container backdrop-blur-md">*/}
            {/*    <Spin size="large"/>*/}
            {/*</div>}*/}

            <TermsOfService
                id="termsOfService"
                isOpened={isTermsOpened}
                setIsOpened={setIsTermsOpened}
            />
        </div>

    );
};

export default PrivateWrapper;
