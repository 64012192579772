export const TOAST_DURATION = 5000;
export const TOAST_BOTTOM_CENTER = "bottom-center";
export const TOAST_TOP_RIGHT = "top-right";

export const MAIN_LOGO =
    "https://imagedelivery.net/tnBAnKUXVi31zAYsXW3cew/fdbb1afa-128e-43dd-e6e8-a4aefb247f00/public";
export const MAIN_LOGO_TRANSPARENT =
    "https://imagedelivery.net/tnBAnKUXVi31zAYsXW3cew/a02159ae-e012-4259-3a40-75f51668aa00/public";

export const RECAPTCHA_KEY = "6Leg1WEiAAAAAPdRPPvRhJdXwy_srDUHbgnykVVr";
export const RECAPTCHA_SECRET_KEY = "6Leg1WEiAAAAAL6TcFs37msauR5qzZQYtVJtWSKb";
export const VAPID_KEY =
    "BIZaRyb0zD-lZcWudLiO8BFWzhAc8LXfyYeLBMnhnquI5iEoEI2e-_UnGE9ZJh31uz0v4Ejv9ESpxpR2pwTYHUg";

export const EMAIL_PATTERN: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;

export const VAT_PATTERN: RegExp =
    /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/;

export const BUSINESS_EMAIL_PATTERN: RegExp =
    /^[a-zA-Z0-9_.+-]+@(?!(gmail|yahoo|hotmail|outlook|aol)).*\.[a-zA-Z]{2,6}$/i;

export const PASSWORD_MIN_LENGTH = 6;
export const IBAN_PATTERN: RegExp = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/;
export const PASSWORD_PATTERN: RegExp =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[A-Z])(?=.*[@$!%*#?&_^( )+=<>-])[A-Za-z\d@$!%*#?&_^( )+=<>-]{6,}$/;

//requires http or https
export const URL_PATTERN =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gm;

//requires optional http, https or www
export const URL_PATTERN_HTTPS_OPTIONAL =
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

export const FORM_DELAY = 1000;
export const CONTACT_PHONE_NUMBER: string = "+356 2124 7174";
export const CONTACT_EMAIL_ADDRESS: string = "support@inplaytip.com";

export const CONTACT_US = "https://www.inplaytip.com/contact-us/";

export const PHONE_MAX_LENGTH = 13;

export const MOBILE_MAX_WIDTH_QUERY = "(max-width: 700px)";
export const SMALL_MOBILE_MAX_WIDTH_QUERY = "(max-width: 450px)";
export const TABLET_MAX_WIDTH_QUERY = "(max-width: 1280px)";

export const TABLE_CLASSES = {
    thead: "gridjs-thead table-dark",
    table: "gridjs-table table table-hover table-striped",
    tr: "gridjs-tr",
    td: "gridjs-td",
    th: "gridjs-th gridjs-th-sort",
};

export const DOMAIN_TERMS = {
    Permanent: "permanent",
    Years: "years",
    Months: "months",
};

export const SHOW_POTENTIAL_PROFIT = false;

export const TELEGRAM_URLS = {
    prod: 'https://t.me/PlayTipBot',
    dev: 'https://t.me/InPlayTipsDevelopmentBot',
    staging: 'https://t.me/InPlayTipsStagingBot',
    qa: 'https://t.me/InPlayTipsQABot',
}

export const PUBLIC_ACCESS_TOKEN = 'UvWcSoh5oImJ5aDMvoPAKOogy9LsLH0i';

export const VAT_REGISTRATION_NUMBER = "MT25462404";

export const ALL_ACCESS_URLS = ["blog", "blog-detail", "privacy-policy", "overview", "pricing", "help", "info"];
