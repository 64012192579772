import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import MainWrapper from "../../Layouts/MainWrapper";
import AccountingService from "../../Services/Accounting/AccountingService";
import Loading from "../../Components/Loading";
import {gtagEventHandler} from "../../Utils/gtagEventHandler";
import {SubscriptionPlanType} from "../../Models/Subscription";
import {currencyFormatter} from "../../Utils/currencyFormatter";
import {ReactComponent as TrophyIcon} from "../../Assests/Icons/trophy-outlined.svg";
import {ReactComponent as CheckIcon} from "../../Assests/Icons/check-blue.svg";
import {useLoginPopUp} from "../../Context/LoginContext";
import {useAppData} from "../../Context/AppContext";
import {PRICING_PLANS} from "../../Utils/constants";

const Pricing = () => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [subscriptionPlans, setSubscriptionPlans] = useState<Array<SubscriptionPlanType>>();
    const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState<number | null>(null);

    const {setIsLoginPopupOpen, setIsRegistered} = useLoginPopUp();
    const {setGoToPricing} = useAppData();

    const fetchAllPricingPlans = () => {
        setIsLoading(true);
        AccountingService.getPricingPlans(false).then((response) => {
            setSubscriptionPlans(response?.result);

            if (response?.result) {
                setSelectedSubscriptionPlan(response?.result[0].id);
            }

            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
            setIsLoading(false);
        });
    }

    const subscriptionPlanDetailHandler = () => {
        const selectedPlan: SubscriptionPlanType | null = subscriptionPlans?.find((plan) => plan.id === selectedSubscriptionPlan) || null;

        if (!selectedPlan) {
            return null;
        }

        return <div className="w-2/3 lg:w-2/4">
            <TrophyIcon/>

            <h5 className="text-sm font-sans font-light text-gray-darker my-4">
                {t(PRICING_PLANS?.find((plan: any) => plan?.pricing_name?.name === selectedPlan?.name.toLowerCase())?.description || "")}
            </h5>

            <h5 className="text-4xl font-sans text-[#003E4F] mb-4 font-bold pb-0">
                {currencyFormatter(selectedPlan.amount, selectedPlan.currencyIso3)}
            </h5>

            <div className="flex justify-content-center mb-4 w-75 m-auto">
                <button
                    className="flex items-center justify-center rounded-full w-full border-1 border-gray-darkest py-[10px] text-[#003E4F] text-xl font-sans font-medium"
                    disabled={!selectedPlan?.id || isLoading}
                    onClick={() => {
                        const planId = selectedPlan?.id || null

                        if (!planId) {
                            return;
                        }

                        setIsRegistered(true);

                        setGoToPricing(true);

                        setIsLoginPopupOpen(true);
                    }}
                >
                    {t("topup-start-free-trial")}
                </button>
            </div>

            {PRICING_PLANS?.find((plan: any) => plan?.pricing_name?.name === selectedPlan.name.toLowerCase())?.features.map((feature: any, index: number) => {
                return <div className="flex items-center mb-2">
                        <span className="text-base font-sans font-medium ms-2 text-gray-darker">
                            <strong
                                className="flex items-center">
                            <CheckIcon/>
                            <span className="ms-2 text-gray-darkest">{t(feature.feature || "")}: </span>
                        </strong>
                            <span className="text-sm font-sans font-light">{t(feature.featuredesc || "")}</span>
                        </span>
                </div>
            })
            }

            <div className="flex justify-content-center w-75 m-auto mt-4">
                <button
                    className="flex bg-blue items-center justify-center rounded-full w-full py-[10px] text-white uppercase text-xl font-sans font-medium"
                    disabled={!selectedPlan?.id || isLoading}
                    onClick={() => {
                        const planId = selectedPlan?.id || null

                        if (!planId) {
                            return;
                        }

                        setIsRegistered(true);
                        setGoToPricing(true);

                        setIsLoginPopupOpen(true);
                    }}
                >
                    {t("topup-subscription-buy")}
                </button>
            </div>

        </div>
    }

    useEffect(() => {
        fetchAllPricingPlans();
    }, []);

    return (
        <MainWrapper>
            <div className="xl:container xl:mx-auto mt-32">
                <div className="flex items-center justify-center">
                    <h5 className="bg-light-green text-dark-green text-xl tracking-wide font-sans font-normal leading-6 px-6 py-2 rounded-md">Pricing</h5>
                </div>

                <div className="flex items-center justify-center">
                    <div className="col-md-8 mt-4 mb-5 mx-3 xl:mx-0">
                        <div className="flex justify-content-center">
                            <div className="flex justify-content-center border-2 border-gray-light rounded-full">
                                {subscriptionPlans && subscriptionPlans.map((plan) => {
                                    return <button
                                        key={plan?.id}
                                        className={`flex items-center justify-center rounded-full w-32 py-[10px] ${selectedSubscriptionPlan === plan.id ? "bg-blue text-white" : ""}`}
                                        onClick={() => {
                                            setSelectedSubscriptionPlan(plan?.id);

                                            gtagEventHandler({
                                                event_category: "Topup",
                                                event_label: "Subscription Plan",
                                                value: plan.id || "",
                                            }, "topup_subscription_plan");
                                        }}
                                    >
                                        {plan?.name}
                                    </button>
                                })}
                            </div>
                        </div>

                        <div
                            className="flex justify-content-center mt-10 border-2 border-gray-light rounded-md shadow-md p-4"
                        >
                            {subscriptionPlanDetailHandler()}
                        </div>

                    </div>
                </div>
            </div>

            {isLoading && <Loading/>}
        </MainWrapper>
    )
}

export default Pricing;