import React, {useEffect, useState} from "react";

import {ReactComponent as EditIcon} from "../../Assests/Icons/edit.svg";
import {ReactComponent as BallIcon} from "../../Assests/Icons/ball.svg";
import {useTranslation} from "react-i18next";
import {SubmitHandler, useForm} from "react-hook-form";
import ProfileService from "../../Services/Profile/ProfileService";
import Toast from "../../Utils/ToastHandler";
import {TELEGRAM_URLS, TOAST_BOTTOM_CENTER, TOAST_DURATION} from "../../Constants/Values";
import AuthService from "../../Services/Auth/AuthService";
import Popup from "../Popup/Popup";
import {Button, Skeleton, Switch, TimePicker} from "antd";
import Input from "../Forms/Input/Input";
import {InfoCircleOutlined} from "@ant-design/icons";
import {Tooltip} from "react-tooltip";
import {COUNTRY_CODES, LANGUAGE_CODES} from "../../Utils/constants";
import Dropdown from "../Dropdown/Dropdown";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import TokenHandler from "../../Utils/TokenHandler";

import "./ProfileSide.scss";
import AssetsService from "../../Services/Assets/AssetsService";
import {SubscriptionProps} from "../../Models/Subscription";
import {ReactComponent as WarningIcon} from "../../Assests/Icons/warning.svg";

type BlackoutProps = {
    countryIso2: string,
    status: string,
    description: string,
    customStartTime: string | null,
    customEndTime: string | null,
    defaultStartTime: string | null,
    defaultEndTime: string | null,
    statusId: number,
}

type ProfileType = {
    firstName: string;
    surName: string;
    email: string;
    country: string;
    otp?: string;
    language: string;
}

type SettingsType = {
    startTime?: string,
    endTime?: string,
}

type ContactInfoType = {
    phone: string;
    email: string;
    oldEmail: string;
    emailVerified: boolean;
}

type Props = {
    setUserName: any;
    loading: boolean;
    setLoading: (arg1: boolean) => void;
    fetchBalance: () => void;
    currentSubscription: SubscriptionProps;
}

const {
    REACT_APP_ENV,
    REACT_APP_PAYMENT_MODEL
} = (window as any).__env__;

dayjs.extend(customParseFormat);

const ProfileSide = ({setUserName, loading, setLoading, fetchBalance, currentSubscription}: Props) => {
    const {t, i18n} = useTranslation();

    const utc = require('dayjs/plugin/utc');

    dayjs.extend(utc)

    const [profile, setProfile] = useState<ProfileType>({
        firstName: "",
        surName: "",
        email: "",
        country: "",
        language: "",
    });

    const [profileBackup, setProfileBackup] = useState<ProfileType>({
        firstName: "",
        surName: "",
        email: "",
        country: "",
        language: "",
    });

    const [verificationPopupVisible, setVerificationPopupVisibility] = useState<boolean>(false);

    const [otp, setOtp] = useState<string>("");
    const [phoneCountryCode, setPhoneCountryCode] = useState<string>("MT");

    const [languageList, setLanguageList] = useState<any[]>([]);
    const [contactInfo, setContactInfo] = useState<ContactInfoType>({
        phone: "",
        email: "",
        oldEmail: "",
        emailVerified: false,
    });

    const [editMode, setEditMode] = useState<any>({
        userName: false,
        email: false,
        phone: false,
        country: false,
        language: false,
    });

    const [notificationCheck, setNotificationCheck] = useState<boolean>(false);

    const [selectedDropdown, setSelectedDropdown] = useState<string>("1");

    const [statusInfo, setStatusInfo] = useState<BlackoutProps>({
        countryIso2: "",
        status: "",
        statusId: 0,
        description: "",
        customStartTime: "",
        customEndTime: "",
        defaultStartTime: "",
        defaultEndTime: "",
    });

    const externalReference = TokenHandler?.getDecodedToken()?.UserExternalReference;

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm<ProfileType>({
        mode: 'onBlur',
    });

    const {
        register: registerSettings,
        handleSubmit: handleSubmitSettings,
        reset: resetSettings,
        formState: {errors: errorsSettings},
    } = useForm<SettingsType>({
        mode: 'onBlur',
    });

    const getProfileHandler = () => {
        setLoading(true);
        ProfileService.getProfile().then((response) => {
            setProfile({
                ...profile,
                firstName: response.name || "",
                surName: response.lastName || "",
                country: response.taxCountry || "",
                language: response?.languageIso2.toLowerCase() || "en",
            });

            setProfileBackup({
                ...profile,
                firstName: response.name || "",
                surName: response.lastName || "",
                country: response.taxCountry || "",
                language: response?.languageIso2.toLowerCase() || "en",
            });

            setUserName({
                firstName: response.name || "",
                lastName: response.lastName || "",
            })

            reset({
                firstName: response.name || "",
                surName: response.lastName || "",
                country: response.taxCountry || "",
                language: response?.languageIso2.toLowerCase() || "en",
            });

            if (response.languageIso2 === "") {
                getLanguageGeolocationHandler();
            } else {
                i18n.changeLanguage(response?.languageIso2.toLowerCase());
            }

            if (response.taxCountry === "") {
                getLanguageGeolocationHandler(true);
            }

            setLoading(false);

        }).then(() => {
            if (!externalReference) {
                fetchBalance();
            }
        }).catch((error) => {
            setLoading(false);
            Toast.error(t("generic-error"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
        });
    }

    const getLanguageListHandler = () => {
        setLoading(true);
        ProfileService.getLanguageList().then((response) => {
            setLoading(false);
            setLanguageList(response);
        }).catch((error) => {
            setLoading(false);
        });
    }

    const getLanguageGeolocationHandler = (taxCountryOnly: boolean = false) => {
        AssetsService.getRegion()
            .then((res: any) => {
                if (!taxCountryOnly) {
                    const language = res?.languageIso2.toLowerCase();

                    i18n.changeLanguage(language);
                } else {
                    setPhoneCountryCode(COUNTRY_CODES.find((o) => o.code === res?.region)?.code || "");
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const getContactInfoHandler = () => {
        AuthService.getEmail().then((response) => {
            setContactInfo({
                ...contactInfo,
                phone: response.phone || "",
                email: response.email || "",
                oldEmail: response.email || "",
                emailVerified: response.emailVerified || false,
            });

            reset({
                email: response.email || "",
            })

            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        })
    }

    const submitForm: SubmitHandler<ProfileType> = () => {
        //CD 20/12/2024: I commented this line of code IN-950 - Language not updateable if Email is not verified
        // if (profileBackup.firstName && profileBackup.surName && profileBackup.country && !contactInfo.emailVerified) {
        //     updateEmailHandler();
        //     return;
        // }

        setLoading(true);

        const payload = {
            name: profile.firstName,
            lastName: profile.surName,
            taxCountry: profile.country,
            languageIso2: profile.language,
        }

        ProfileService.updateProfile(payload).then(() => {
            setLoading(false);
            setEditMode({
                userName: false,
                email: false,
                phone: false,
                country: false
            })
            if (!contactInfo.emailVerified) {
                updateEmailHandler();
            }
        }).then(() => {
            getProfileHandler();
            getContactInfoHandler()
            Toast.success(t("Profile-Save-Success"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
        }).catch((error) => {
            setEditMode({
                userName: false,
                email: false,
                phone: false,
                country: false
            })
            setLoading(false);
            Toast.error(t("generic-error"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
        });
    }

    const updateEmailHandler = () => {
        if (contactInfo.email === contactInfo.oldEmail) {
            return;
        }

        setLoading(true);

        const payload = {
            email: contactInfo.email,
        }

        AuthService.updateEmail(payload).then(() => {
            setLoading(false);
            getContactInfoHandler();
            getEmailVerification();
            setEditMode({
                userName: false,
                email: false,
                phone: false,
                country: false
            })
            AuthService.refreshToken().then().catch((e) => console.log(e));
        }).catch((err) => {
            setLoading(false);

            setEditMode({
                userName: false,
                email: false,
                phone: false,
                country: false
            })

            const error = err.response.data.Error.Message;

            if (error === "NotAllowedToEditEmailIn5Minutes") {
                Toast.error(t("Profile-NotAllowedToEditEmailIn5Minutes"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
            } else if (error === "InvalidEmail") {
                Toast.error(t("InvalidEmail"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
            } else {
                Toast.error(t("generic-error"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
            }
        });
    }

    const getEmailVerification = (resend: boolean = false) => {
        setLoading(true);
        ProfileService.getEmailOtp(contactInfo.email).then(() => {
            setLoading(false);
            if (!resend) {
                setVerificationPopupVisibility(true)
            }

            Toast.success(t("Profile-Email-Verification-OTP-Sent"), TOAST_DURATION, TOAST_BOTTOM_CENTER);

        }).catch((err) => {
            setLoading(false)

            const error = err.response.data.Error.Message;

            if (!resend && error !== "InvalidEmail") {
                setVerificationPopupVisibility((prev) => !prev)
            }

            if (error === "NotAllowedToEditEmailIn5Minutes") {
                Toast.success(t("NotAllowedToEditEmailIn5Minutes"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
            } else if (error === "InvalidEmail") {
                Toast.error(t("InvalidEmail"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
            } else {
                Toast.error(t("generic-error"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
            }
        })
    }

    const verifyEmailHandler = () => {
        setLoading(true);

        ProfileService.verifyEmail(otp).then(() => {
            setLoading(false);
            setVerificationPopupVisibility(false);
            getContactInfoHandler();
            setEditMode({
                userName: false,
                email: false,
                phone: false,
                country: false
            })
            Toast.success(t("Profile-Email-Verification-Success"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
        }).catch((err) => {
            const error = err.response.data.Error.Message;

            if (error === "InvalidCode") {
                Toast.error(t("InvalidCode"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
            } else {
                Toast.error(t("generic-error"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
            }
            setEditMode({
                userName: false,
                email: false,
                phone: false,
                country: false
            })
            setLoading(false);

        });
    }

    const UTCtoLocalConverter = (time: string, reverse: boolean = false) => {
        if (reverse) {
            const date = new Date();
            const hours = parseInt(time.split(":")[0]);
            const minutes = parseInt(time.split(":")[1]);
            const seconds = parseInt(time.split(":")[2]);

            return dayjs.utc(date.setHours(hours, minutes, seconds, 0)).format("HH:mm:ss");
        }

        return dayjs.utc(time, "HH:mm:ss").local().format("HH:mm:ss");
    }

    const serviceStatusHandler = () => {
        setLoading(true);
        ProfileService.getStatus().then((res) => {
            const status = res?.status;
            setSelectedDropdown(status === "Enabled" ? "1" : status === "Paused" ? "2" : "3");
            setStatusInfo({
                countryIso2: res?.countryIso2,
                status: res?.status,
                statusId: res?.statusId,
                description: res?.description,
                customStartTime: res?.customStartTime ? UTCtoLocalConverter(res?.customStartTime) : "",
                customEndTime: res?.customEndTime ? UTCtoLocalConverter(res?.customEndTime) : "",
                defaultStartTime: res?.defaultStartTime ? UTCtoLocalConverter(res?.defaultStartTime) : "",
                defaultEndTime: res?.defaultEndTime ? UTCtoLocalConverter(res?.defaultEndTime) : "",
            });

            resetSettings({
                startTime: res?.customStartTime ? UTCtoLocalConverter(res?.customStartTime) : "",
                endTime: res?.customEndTime ? UTCtoLocalConverter(res?.customEndTime) : "",
            })

            setLoading(false);
        }).catch((e) => {
            Toast.error(t("generic-error"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
            setLoading(false);
        });
    }

    const submitNotificationForm: SubmitHandler<any> = (status) => {

        const customStartTime = statusInfo.customStartTime ? statusInfo.customStartTime : statusInfo.defaultStartTime;
        const customEndTime = statusInfo.customEndTime ? statusInfo.customEndTime : statusInfo.defaultEndTime;

        const payload = {
            newStatus: Number(status),
            ...(status === "3") && {
                BlackoutTime: {
                    "BlackoutFrom": UTCtoLocalConverter(customStartTime || "", true),
                    "BlackoutTo": UTCtoLocalConverter(customEndTime || "", true),
                }
            },
        }

        setLoading(true);
        ProfileService.setStatus(payload).then(() => {
            setSelectedDropdown(status);
            serviceStatusHandler();
            setLoading(false);
            Toast.success(t("Settings-Blackout-Saved-Successfully"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
        }).catch((e) => {
            setLoading(false);
            Toast.error(t("generic-error"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
        });
    }

    const telegramLinkHandler = () => {
        if (REACT_APP_ENV === "dev") {
            window.open(TELEGRAM_URLS.dev, "_blank");
        } else if (REACT_APP_ENV === "staging") {
            window.open(TELEGRAM_URLS.staging, "_blank");
        } else if (REACT_APP_ENV === "qa") {
            window.open(TELEGRAM_URLS.qa, "_blank");
        } else {
            window.open(TELEGRAM_URLS.prod, "_blank");
        }
    }

    const subscriptionRenewalHandler = () => {
        const subscriptionStartingPaymentDate = currentSubscription?.startingPaymentDate; //2024-11-19T09:17:16.984528+00:00
        const subscriptionDuration = currentSubscription?.durationInMonth; // 1
        const subscriptionIsSuspended = currentSubscription?.isSuspended;
        const subscriptionAutoRenew = currentSubscription?.autoRenewable;
        const subscriptionName = currentSubscription?.name;

        const renewalDate = dayjs(subscriptionStartingPaymentDate).add(subscriptionDuration, 'month').format("DD/MM/YYYY");

        if (subscriptionIsSuspended) {
            return <div className="flex gap-1 flex-col">
                <h5 className="text-lg font-sans font-medium text-light-black leading-none">{t("profile-subscription-Suspended")}</h5>
            </div>
        } else if (!subscriptionAutoRenew) {
            return <div className="flex gap-1 flex-col">
                <h5 className="text-lg font-sans font-medium text-light-black leading-none">
                    {
                        dayjs(renewalDate, "DD/MM/YYYY").diff(dayjs(), 'days') > 0 ?
                            `${dayjs(renewalDate, "DD/MM/YYYY").diff(dayjs(), 'days') || 0} ${t("profile-subscribe-days-left")}` :
                            `${t("profile-subscribe-expired")}`
                    }
                </h5>
                {
                    dayjs(renewalDate, "DD/MM/YYYY").diff(dayjs(), 'days') > 0 &&
                    <h5 className="text-lg font-sans font-medium text-light-black leading-none"> {`${t(subscriptionName)} ${t("profile-subscribe-plan")}`}</h5>
                }

            </div>
        } else {
            return <div className="flex gap-1 flex-col">
                <h5 className="text-lg font-sans font-medium text-light-black leading-none">
                    {`${t("profile-subscribe-next-payment")} ${dayjs(renewalDate, "DD/MM/YYYY").format("MMM DD, YYYY")}`}
                </h5>
                <h5 className="text-lg font-sans font-medium text-light-black leading-none"> {`${t(subscriptionName)} ${t("profile-subscribe-plan")}`}</h5>
            </div>
        }

    }

    useEffect(() => {
        getLanguageListHandler();
        getProfileHandler();
        getContactInfoHandler();
        serviceStatusHandler();
    }, []);

    return <React.Fragment>
        {
            statusInfo?.statusId === 4 ?
                <div className="bg-white p-4 rounded-md mt-6 shadow-lg border-2 border-orange border-opacity-50">
                    <h6 className="text-sm font-semibold font-sans">{t("profile-side-message")}</h6>
                    <span
                        className="text-xs font-sans leading-tight text-light-black">{t("Setting-Blocked-Message")}</span>
                    <hr/>
                    <div className="d-flex justify-content-center">
                        <Button
                            className="buttpx-3"
                            type="primary"
                            onClick={() => telegramLinkHandler()}
                        >
                            {t("profile-side-start-receiving-tips")}
                        </Button>
                    </div>
                </div> :
                <div>
                    <div className="flex justify-between items-center my-8 px-8">
                        <h5 className="text-sm font-sans font-medium text-light-black">{t("profile-side-tip-notifications")}</h5>

                        <input
                            className="checkbox-notification"
                            type="checkbox"
                            id="notification-check"
                            checked={selectedDropdown ? (selectedDropdown === "1" || selectedDropdown === "3") : false}
                            onChange={(e) => {
                                const value = e.target.checked;
                                const status = value ? "1" : "2";

                                submitNotificationForm(status)

                                setSelectedDropdown(status)
                            }}
                        />
                        <label htmlFor="notification-check"></label>
                    </div>

                    {(selectedDropdown === "1" || selectedDropdown === "3") && <div className="mb-6">
                        <h5 className="text-sm font-sans font-medium text-gray-darker text-center">Pause for</h5>

                        <form onSubmit={handleSubmitSettings(() => submitNotificationForm(selectedDropdown))}>
                            <div
                                className="flex flex-col items-center bg-white p-4 rounded-md shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]">

                                <div className="flex items-center col-12 gap-1 ">
                                    <div className="col-6">
                                        <div className="flex flex-col items-center justify-center">
                                            <h5 className="text-sm font-sans font-medium text-gray-darker">{t("profile-side-pause-for")}</h5>
                                            <input
                                                type="time"
                                                id="startTime"
                                                disabled={selectedDropdown !== "3"}
                                                value={statusInfo.customStartTime ? statusInfo.customStartTime : statusInfo.defaultStartTime ?
                                                    statusInfo.defaultStartTime : ""}
                                                {...registerSettings("startTime", {
                                                    onChange: (e) => {
                                                        const startTime = dayjs(e.target.value, "HH:mm").format("HH:mm:ss");

                                                        setStatusInfo({
                                                            ...statusInfo,
                                                            customStartTime: startTime
                                                        });
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: t("Settings-Blackout-From-Required"),
                                                    },
                                                    validate: (value) => {
                                                        if (statusInfo.customEndTime) {
                                                            if (dayjs(value, "HH:mm").isSame(dayjs(statusInfo.customEndTime, "HH:mm"))) {
                                                                return t("Settings-Blackout-From-Less-Than-End-Time");
                                                            }
                                                        }

                                                        return true;
                                                    }
                                                })}
                                                name="startTime"
                                                className="border-1 border-gray-darker p-2 rounded-md text-sm w-full"
                                                required
                                            />
                                        </div>

                                        {/*<div className="flex items-center mt-2 terms-conditions-checkbox">*/}
                                        {/*    <input*/}
                                        {/*        type="checkbox"*/}
                                        {/*        disabled*/}
                                        {/*        id="today-checkbox"*/}
                                        {/*        className="me-3 cursor-not-allowed"*/}
                                        {/*        value="option2"*/}
                                        {/*        onClick={() => {*/}
                                        {/*            // setFailedCredit(!failedCredit)*/}
                                        {/*        }}*/}
                                        {/*    />*/}
                                        {/*    <label htmlFor="today-checkbox"*/}
                                        {/*           className="text-gray-dark font-sans font-normal text-sm cursor-not-allowed">*/}
                                        {/*        Today*/}
                                        {/*    </label>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="col-6">
                                        <div className="flex flex-col items-center justify-center">
                                            <h5 className="text-sm font-sans font-medium text-gray-darker">{t("profile-side-pause-to")}</h5>

                                            <input
                                                type="time"
                                                id="endTime"
                                                disabled={selectedDropdown !== "3"}
                                                value={statusInfo.customEndTime ? statusInfo.customEndTime : statusInfo.defaultEndTime ?
                                                    statusInfo.defaultEndTime : ""}
                                                {...registerSettings("endTime", {
                                                    onChange: (e) => {
                                                        const endTime = dayjs(e.target.value, "HH:mm").format("HH:mm:ss");

                                                        setStatusInfo({
                                                            ...statusInfo,
                                                            customEndTime: endTime
                                                        });
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: t("Settings-Blackout-To-Required"),
                                                    },
                                                    validate: (value) => {
                                                        if (statusInfo.customStartTime) {
                                                            if (dayjs(value, "HH:mm").isSame(dayjs(statusInfo.customStartTime, "HH:mm"))) {
                                                                return t("Settings-Blackout-To-Greater-Than-Start-Time");
                                                            }
                                                        }

                                                        return true;
                                                    }
                                                })}
                                                name="endTime"
                                                className="border-1 border-gray-darker p-2 rounded-md text-sm w-full"
                                                required
                                            />
                                        </div>

                                        {/*<div className="flex items-center mt-2 terms-conditions-checkbox ">*/}
                                        {/*    <input*/}
                                        {/*        type="checkbox"*/}
                                        {/*        id="everyday-checkbox"*/}
                                        {/*        disabled*/}
                                        {/*        className="me-3"*/}
                                        {/*        value="option2"*/}
                                        {/*        onClick={() => {*/}
                                        {/*            // setFailedCredit(!failedCredit)*/}
                                        {/*        }}*/}
                                        {/*    />*/}
                                        {/*    <label htmlFor="everyday-checkbox"*/}
                                        {/*           className="text-gray-dark font-sans font-normal text-sm cursor-not-allowed">*/}
                                        {/*        Every Day*/}
                                        {/*    </label>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>

                                <div className="flex items-center justify-center mt-3">
                                    <h5 className="text-xs font-sans font-normal text-gray-darker">{t("profile-side-timezone")} {
                                        new Date()?.toString()?.match(/\(([A-Za-z\s].*)\)/)?.[1] + " " + `(${new Date().toLocaleTimeString('en-us', {timeZoneName: 'short'}).split(' ')[2]})`
                                    }</h5>
                                </div>

                                <div className="w-full flex justify-between items-center flex-row mt-3">
                                    <div>
                                        {(errorsSettings.startTime || errorsSettings.endTime) &&
                                            <div className="">
                                                {errorsSettings?.startTime &&
                                                    <span
                                                        className="text-sm text-[#FF0000] font-sans">{errorsSettings?.startTime.message}<br/></span>}
                                                {errorsSettings.endTime &&
                                                    <span
                                                        className="text-sm text-[#FF0000] font-sans">{errorsSettings.endTime.message}</span>}
                                            </div>}
                                    </div>

                                    {selectedDropdown === "3" &&
                                        <Button
                                            className={"float-end"}
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            {t("profile-side-save")}
                                        </Button>}
                                </div>
                            </div>

                            <div className="flex items-center justify-center mt-2">

                                <h5 className="text-sm font-sans font-medium text-gray-darker pe-3 pt-1">{t("profile-side-blackout-time")}</h5>

                                <input
                                    className="checkbox-notification"
                                    type="checkbox"
                                    id="blackout-check"
                                    checked={selectedDropdown === "3"}
                                    onChange={(e) => {
                                        //make sure the paused for and to are filled if not show an error and return
                                        const value = e.target.checked;
                                        const status = value ? "3" : "1";

                                        if (!statusInfo.customStartTime || !statusInfo.customEndTime) {
                                            setSelectedDropdown(status)
                                            Toast.error(t("Settings-Blackout-From-To-Required-And-Save"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
                                            return;
                                        }

                                        submitNotificationForm(status)
                                    }}
                                />
                                <label htmlFor="blackout-check"></label>
                            </div>
                        </form>
                    </div>}
                </div>
        }

        <div className="flex justify-between items-center bg-white p-4 rounded-md d-none shadow-lg">
            <div className="flex gap-0 justify-center flex-col ">
                <h5 className="text-sm font-sans font-normal text-light-black">Next Tip</h5>
                <h5 className="text-xs font-sans font-normal text-gray-darker">France U19 League</h5>
                <h5 className="text-xs font-sans font-normal text-gray-darker">PSG U19 vs Dunkerque
                    U19</h5>
            </div>

            <div className="flex items-start my-2">
                <BallIcon/>
            </div>
        </div>

        <form onSubmit={handleSubmit(submitForm)}>
            <div
                className={`p-4 rounded-md mt-6 shadow-lg ${(profileBackup.firstName === "" || profileBackup.surName === "") ? "bg-danger-alert" : "bg-white"}`}>
                <div className="flex justify-between items-center">
                    <h5 className={`text-sm font-sans font-medium ${(profileBackup.firstName === "" || profileBackup.surName === "") ? "text-dark" : "text-gray-darker"}`}>{t("private-wrapper-profile")}</h5>
                    <EditIcon
                        className={`${profileBackup.firstName !== "" || profileBackup.surName !== "" ? "cursor-not-allowed" : "cursor-pointer"}`}
                        onClick={() => {
                            if (profileBackup.firstName !== "" || profileBackup.surName !== "") {
                                return;
                            }
                            setEditMode({...editMode, userName: !editMode.userName})
                        }}
                    />
                </div>
                <div className="flex items-start my-2">
                    {editMode.userName ?
                        <div className="w-full">
                            {t("Profile-Label-Name")}
                            <Input
                                id="firstname"
                                name="firstname"
                                type="text"
                                value={profile.firstName}
                                readonly={profileBackup.firstName !== ""}
                                withMargin={false}
                                required={true}
                                customRef={register("firstName", {
                                    onChange: (e) =>
                                        setProfile({
                                            ...profile,
                                            firstName: e.target.value,
                                        }),
                                    required: {
                                        value: true,
                                        message: t("Profile-Label-Name-Required"),
                                    },
                                })}
                                error={errors.firstName?.message}
                                labelText={t("Profile-Label-Name")}
                                labelType={"none"}
                                requiredMark={true}
                                divClassName="w-full"
                                labelClassName="col-form-label"
                            />
                            {t("Profile-Label-Surname")}
                            <Input
                                id="surname"
                                name="surname"
                                type="text"
                                value={profile.surName}
                                readonly={profileBackup.surName !== ""}
                                withMargin={false}
                                required={true}
                                labelType={"none"}
                                labelText={t("Profile-Label-Surname")}
                                divClassName="col-md-12"
                                labelClassName="col-form-label"
                                customRef={register("surName", {
                                    onChange: (e) =>
                                        setProfile({
                                            ...profile,
                                            surName: e.target.value,
                                        }),
                                    required: {
                                        value: true,
                                        message: t("Profile-Label-Surname-Required"),
                                    },
                                })}
                                error={errors.surName?.message}
                                requiredMark={true}
                            />
                            <div className="flex gap-1">
                                <Button
                                    id="saveButton"
                                    type="primary"
                                    htmlType="submit"
                                    disabled={loading}
                                >
                                    {t("profile-side-save")}
                                </Button>

                                <Button
                                    id="cancelButton"
                                    type="primary"
                                    danger
                                    className="cancel-button"
                                    onClick={() => {
                                        setProfile({
                                            ...profile,
                                            firstName: profileBackup.firstName,
                                            surName: profileBackup.surName,
                                        });
                                        setEditMode({...editMode, userName: !editMode.userName});
                                    }}
                                >
                                    {t("Cancel-Button")}
                                </Button>
                            </div>
                        </div> : loading ? <Skeleton.Input size="default" active={true}/> :
                            <h5 className="text-lg font-sans font-medium text-light-black">{`${profile.firstName || ""} ${profile.surName || ""}`}</h5>}
                </div>
            </div>

            <div
                className={`p-4 rounded-lg mt-6 shadow-lg ${(!contactInfo.emailVerified || contactInfo.email === "") ? "bg-danger-alert" : "bg-white"}`}>
                <div className="flex justify-between items-center">
                    <h5 className={`text-sm font-sans font-medium ${(!contactInfo.emailVerified || contactInfo.email === "") ? "text-dark" : "text-gray-darker"}`}>
                        {t("profile-side-email")}</h5>
                    <EditIcon
                        className={`${contactInfo.emailVerified ? "cursor-not-allowed" : "cursor-pointer"}`}
                        onClick={() => {
                            if (contactInfo.emailVerified) {
                                return;
                            }

                            setEditMode({...editMode, email: !editMode.email})
                        }}
                    />
                </div>

                <div className="flex items-start my-2">
                    {editMode.email ? <div className="w-full">
                        <div>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Email"
                                aria-label="Email"
                                aria-describedby="email-verify"
                                value={contactInfo.email}
                                disabled={contactInfo.emailVerified}
                                {
                                    ...register("email", {
                                        onChange: (e) =>
                                            setContactInfo({
                                                ...contactInfo,
                                                email: e.target.value,
                                            }),
                                        required: {
                                            value: true,
                                            message: t("Profile-Label-Email-Required"),
                                        },
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: t("Profile-Label-Email-Invalid"),
                                        },
                                    })
                                }
                            />
                            {(!contactInfo.emailVerified && contactInfo.email !== "") && (<div>
                                    <span className="text-error float-end"> {t("profile-side-email-not-verified")} <a
                                        href={"#"}
                                        className="text-error text-decoration-underline"
                                        onClick={() => getEmailVerification()}>{t("profile-side-verify-now")}</a></span><br/>
                                </div>
                            )}
                        </div>
                        <div className="text-error">{errors.email?.message}</div>
                        <div className="flex gap-1 mt-1">
                            <Button
                                id="saveButton"
                                type="primary"
                                htmlType="submit"
                                disabled={loading}
                            >
                                {t("profile-side-save")}
                            </Button>

                            <Button
                                id="cancelButton"
                                type="primary"
                                danger
                                className="cancel-button"
                                onClick={() => {
                                    setContactInfo({
                                        ...contactInfo,
                                        email: contactInfo.oldEmail,
                                    });
                                    setEditMode({...editMode, email: !editMode.email});
                                }}
                            >
                                {t("Cancel-Button")}
                            </Button>
                        </div>

                    </div> : loading ? <Skeleton.Input size="default" active={true}/> :
                        <h5 className="text-lg font-sans font-medium text-light-black">
                            {!contactInfo.emailVerified ?
                                <WarningIcon title={t("profile-side-email-not-verified")}/> : ""}
                            {contactInfo.email.length > 20 ? contactInfo.email.substring(0, 20) + "..." : contactInfo.email || ""}

                        </h5>}
                </div>
            </div>

            <div className="bg-white p-4 rounded-lg mt-6 shadow-lg">
                <div className="flex justify-between items-center">
                    <h5 className="text-sm font-sans font-medium text-gray-darker">{t("profile-side-mobile")}</h5>
                    <EditIcon className="cursor-not-allowed"/>
                </div>
                <div className="flex items-start my-2"> {loading ? <Skeleton.Input size="default" active={true}/> :
                    <h5 className="text-lg font-sans font-medium text-light-black">{contactInfo.phone || ""}</h5>}
                </div>
            </div>

            <div
                className={`p-4 rounded-lg mt-6 shadow-lg ${(profileBackup.country === "") ? "bg-danger-alert" : "bg-white"}`}>
                <div className="flex justify-between items-center">
                    <h5 className={`text-sm font-sans font-medium ${(profileBackup.country === "") ? "text-dark" : "text-gray-darker"}`}>{t("profile-side-tax-country")}
                        <InfoCircleOutlined
                            rev={undefined}
                            data-tooltip-html={t('Profile-Country-Info')}
                            data-tooltip-id="taxCountryTooltip"
                            className="ms-2"
                        />
                        <Tooltip id="taxCountryTooltip"/>
                    </h5>
                    <EditIcon
                        className={`${profileBackup.country !== "" ? "cursor-not-allowed" : "cursor-pointer"}`}
                        onClick={() => {
                            if (profileBackup.country !== "") {
                                return;
                            }
                            setEditMode({...editMode, country: !editMode.country})
                        }}
                    />
                </div>
                <div className="flex items-start my-2">
                    {editMode.country ?
                        <div>
                            <Dropdown
                                selectclass="form-select"
                                id="country"
                                name="country"
                                required={true}
                                disabled={profileBackup.country !== ""}
                                onChange={(e) => {
                                    setProfile({
                                        ...profile,
                                        country: e.target.value,
                                    });
                                }}
                                placeholder={t('profile-side-tax-country-selecty-country-dd')}
                                value={profile.country}
                                options={COUNTRY_CODES.map((o) => {
                                    return {
                                        name: o.name,
                                        value: o.code,
                                    };
                                })}
                            />

                            <div className="flex gap-1">
                                <Button
                                    id="saveButton"
                                    type="primary"
                                    htmlType="submit"
                                    disabled={loading}
                                >
                                    {t("profile-side-save")}
                                </Button>

                                <Button
                                    id="cancelButton"
                                    type="primary"
                                    danger
                                    className="cancel-button"
                                    onClick={() => {
                                        setProfile({
                                            ...profile,
                                            country: profileBackup.country,
                                        });
                                        setEditMode({...editMode, country: !editMode.country});
                                    }}
                                >
                                    {t("Cancel-Button")}
                                </Button>
                            </div>
                        </div> : loading ? <Skeleton.Input size="default" active={true}/> :
                            <h5 className="text-lg font-sans font-medium text-light-black">
                                {!profile.country ? <WarningIcon title={t("profile-side-tax-country-not-set")}/> : ""}
                                {
                                    profile.country ? COUNTRY_CODES.find((o) => o.code === profile.country)?.name || "" : ""}
                            </h5>}
                </div>

            </div>

            <div className="bg-white p-4 rounded-lg mt-6 shadow-lg">
                <div className="flex justify-between items-center">
                    <h5 className="text-sm font-sans font-medium text-gray-darker">{t("profile-side-language")}</h5>
                    <EditIcon
                        className={"cursor-pointer"}
                        onClick={() => {
                            setEditMode({...editMode, language: !editMode.language})
                        }}
                    />
                </div>

                <div className="flex items-start my-2">
                    {editMode.language ?
                        <div
                            className="w-full"
                        >
                            <Dropdown
                                selectclass="form-select"
                                id="language"
                                name="language"
                                required={true}
                                onChange={(e) => {
                                    setProfile({
                                        ...profile,
                                        language: e.target.value,
                                    });
                                }}
                                placeholder="Select language"
                                value={profile.language || localStorage.getItem("i18nextLng") || "en"}
                                options={
                                    LANGUAGE_CODES.filter((o) => {
                                        return languageList.includes(o.code.toUpperCase());
                                    }).map((o) => {
                                        return {
                                            name: `${o.flag} ${o.name}`,
                                            value: o.code,
                                        };
                                    })
                                }
                            />

                            <div className="flex gap-1">
                                <Button
                                    id="saveButton"
                                    type="primary"
                                    htmlType="submit"
                                    disabled={loading}
                                >
                                    {t("profile-side-save")}
                                </Button>

                                <Button
                                    id="cancelButton"
                                    type="primary"
                                    danger
                                    className="cancel-button"
                                    onClick={() => {
                                        setProfile({
                                            ...profile,
                                            language: profileBackup.language,
                                        });
                                        setEditMode({...editMode, language: !editMode.language});
                                    }}
                                >
                                    {t("Cancel-Button")}
                                </Button>
                            </div>
                        </div> : loading ? <Skeleton.Input size="default" active={true}/> :
                            <h5 className="text-lg font-sans font-medium text-light-black">{
                                profile.language ? LANGUAGE_CODES.find((o) => o.code === profile.language)?.name || "" : ""}
                            </h5>}
                </div>
            </div>

            {REACT_APP_PAYMENT_MODEL !== "CREDIT" &&
                <div className="bg-white p-4 rounded-lg mt-6 shadow-lg">
                    <div className="flex justify-between items-center">
                        <h5 className="text-sm font-sans font-medium text-gray-darker">{t("profile-side-subscription")}</h5>
                    </div>

                    <div className="flex items-start my-2">
                        {loading ? <Skeleton.Input size="default" active={true}/> :
                            subscriptionRenewalHandler()
                        }
                    </div>
                </div>
            }

        </form>

        {
            verificationPopupVisible &&
            <Popup
                isOpen={verificationPopupVisible}
                setIsOpen={setVerificationPopupVisibility}
            >
                <div className="col-md-12 my-4">
                    <label htmlFor="otp" className="col-form-label">
                        {t("Profile-Enter-Otp")}
                        <span className="text-error"> *</span>
                    </label>

                    <div className="input-group">
                        <input
                            id="otp"
                            type="text"
                            className="form-control"
                            placeholder="Enter OTP"
                            aria-label="otp"
                            aria-describedby="email-resend"
                            value={otp}
                            disabled={contactInfo.emailVerified}
                            {
                                ...register("otp", {
                                    onChange: (e) =>
                                        setOtp(e.target.value),
                                    required: {
                                        value: true,
                                        message: t("Profile-Email-OTP-Required"),
                                    },
                                    pattern: {
                                        value: /^\d{4}$/,
                                        message: t("Profile-Email-OTP-Invalid"),
                                    },
                                })
                            }
                        />

                        <button
                            className="btn btn-sm btn-outline-dark"
                            type="button"
                            id="email-resend"
                            disabled={loading}
                            onClick={() => getEmailVerification(true)}
                        >
                            {t("profile-side-resend-otp")}
                        </button>


                    </div>
                    <div className="text-error">{errors.otp?.message}</div>

                    <div
                        className="alert alert-success mt-3 mb-2 d-flex justify-content-between align-items-center">
                             <span className="text-green">
                                 {t("Profile-Success-Message")}
                            </span>
                    </div>
                    <Button
                        id="saveButton"
                        type="primary"
                        className="save-button mt-3"
                        htmlType="button"
                        onClick={() => verifyEmailHandler()}
                        disabled={loading || otp === ""}
                    >
                        {t("profile-side-verify-email")}
                    </Button>
                </div>
            </Popup>}

    </React.Fragment>
}

export default ProfileSide;